import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { ResponseSuccess } from "../../models/api.models";
import chatService from "../../services/chat-service";
import { Chats, Messages } from "../../models/chat.models";

const useGetAllChat = (page: number = 1, pageSize: number = 15) => {
    return useQuery<ResponseSuccess<Chats>>(
        {
            queryFn: () => chatService.getAllChat(page, pageSize),
            queryKey: ['chats', page],
            placeholderData: keepPreviousData
        });
}


const useGetChatMessages = (chatId: number, page: number = 1, pageSize: number = 15) => {
    return useQuery<ResponseSuccess<Messages>>(
        {
            queryFn: () => chatService.getChatMessages(chatId, page, pageSize),
            queryKey: ['chat messages', page],
            placeholderData: keepPreviousData
        });
}


export {
    useGetAllChat,
    useGetChatMessages
};
