import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import AddCircle from '@mui/icons-material/AddCircle';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import PersonTable from "../components/Person/PersonTable"
import { useGetAllPerson, useSaveAllPerson } from '../hooks';
import { useState } from 'react';


export default function Person() {
    const [file, setFile] = useState<File | null>(null);
    const [page, setPage] = useState<number>(1);
    const { data, isSuccess, refetch } = useGetAllPerson(page, 15);
    const { mutate } = useSaveAllPerson(refetch);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0] || null;
        setFile(selectedFile)
        if (selectedFile) mutate(selectedFile)

    };

    return (

        <Box
            component="main"
            className="MainContent"
            sx={{
                px: { xs: 2, md: 6 },
                pt: {
                    xs: 'calc(12px + var(--Header-height))',
                    sm: 'calc(12px + var(--Header-height))',
                    md: 3,
                },
                pb: { xs: 2, sm: 2, md: 3 },
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                minWidth: 0,
                height: '100dvh',
                gap: 1,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Breadcrumbs
                    size="sm"
                    aria-label="breadcrumbs"
                    separator={<ChevronRightRoundedIcon />}
                    sx={{ pl: 0 }}
                >
                    <Link
                        underline="none"
                        color="neutral"
                        href="#some-link"
                        aria-label="Home"
                    >
                        <HomeRoundedIcon />
                    </Link>
                    <Link
                        underline="hover"
                        color="neutral"
                        href="#some-link"
                        fontSize={12}
                        fontWeight={500}
                    >
                        Dashboard
                    </Link>
                    <Typography color="primary" fontWeight={500} fontSize={12}>
                        Persons
                    </Typography>
                </Breadcrumbs>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    mb: 1,
                    gap: 1,
                    flexDirection: { xs: 'column', sm: 'row' },
                    alignItems: { xs: 'start', sm: 'center' },
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                }}
            >
                <Typography level="h2" component="h1">
                    Persons
                </Typography>
                {
                    file
                        ?
                        <>
                            <input
                                accept=".xlsx, .xls"
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                type="file"
                                onChange={handleFileChange}
                            />
                            <label htmlFor="raised-button-file">
                                <Button
                                    color="primary"
                                    size="sm"
                                    component="span"
                                >
                                    Seleted File
                                </Button>
                            </label>
                        </>
                        :
                        <>
                            <input
                                accept=".xlsx, .xls"
                                style={{ display: 'none' }}
                                id="raised-button-file"
                                type="file"
                                onChange={handleFileChange}
                            />
                            <label htmlFor="raised-button-file">
                                <Button
                                    color="primary"
                                    startDecorator={<AddCircle />}
                                    size="sm"
                                    component="span"
                                >
                                    Add Persones
                                </Button>
                            </label>
                        </>

                }


            </Box>
            <PersonTable
                rows={isSuccess ? data.data.content : []}
                currenctPage={isSuccess ? data.data.page : 0}
                totalPages={isSuccess ? data.data.totalPages : 0}
                setPage={setPage}
            />
        </Box>

    );
}