import * as React from 'react';
import Sheet from '@mui/joy/Sheet';
import { ChatProps } from '../models/template.type';
import { chats } from '../data';
import ChatsPane from '../components/Messages/ChatsPane';
import MessagesPane from '../components/Messages/MessagesPane';
import { useGetAllChat } from '../hooks';




export default function Message() {
  const {data, isSuccess} = useGetAllChat(1, 15);
  const [selectedChat, setSelectedChat] = React.useState<number>(0);
  React.useEffect(() => {
    console.log(selectedChat)
  }, [selectedChat])
  
  return (
    <Sheet
      sx={{
        flex: 1,
        width: '100%',
        mx: 'auto',
        pt: { xs: 'var(--Header-height)', sm: 0 },
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          sm: 'minmax(min-content, min(30%, 400px)) 1fr',
        },
      }}
    >
      <Sheet
        sx={{
          position: { xs: 'fixed', sm: 'sticky' },
          transform: {
            xs: 'translateX(calc(100% * (var(--MessagesPane-slideIn, 0) - 1)))',
            sm: 'none',
          },
          transition: 'transform 0.4s, width 0.4s',
          zIndex: 100,
          width: '100%',
          top: 52,
        }}
      >
        <ChatsPane
          chats={isSuccess ? data.data.content : []}
          selectedChatId={selectedChat}
          setSelectedChat={setSelectedChat}
        />
      </Sheet>
      {
        selectedChat > 0
        ?
        <MessagesPane chatId={selectedChat} />
        :
        <></>
      }
      
    </Sheet>
  );
}