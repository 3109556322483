import * as React from 'react';
import Box from '@mui/joy/Box';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';

interface Props {
    children: React.ReactNode;
}

const MainLayout: React.FC<Props> = ({ children }) => {
    return (
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
            <Sidebar />
            <Header />
            <Box component="main" className="MainContent" sx={{ flex: 1 }}>
                {children}
            </Box>
        </Box>
    );
}

export default MainLayout;