import client from "../api/client";
import { ResponseSuccess } from "../models/api.models";
import { Chats, Messages } from "../models/chat.models";


class ChatService {

    async getAllChat(page: number, pageSize: number): Promise<ResponseSuccess<Chats> | any> {
        return (await client.get<ResponseSuccess<Chats>>(`/chat?page=${page}&pageSize${pageSize}`)).data;
    }

    async getChatMessages(chatId: number, page: number, pageSize: number): Promise<ResponseSuccess<Messages> | any> {
        return (await client.get<ResponseSuccess<Messages>>(`/chat/message/${chatId}?page=${page}&pageSize${pageSize}`)).data;
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default new ChatService();
