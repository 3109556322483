import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';


interface Props {
    isAuth: boolean;
}

const PrivateRoute: FC<Props> = ({ isAuth }) => {
    const navigate = useNavigate();

    if (isAuth === true) {
        return <Navigate to="/login" />
    } else {
        return <Outlet />;
    }
};

export default PrivateRoute;
