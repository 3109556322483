
import client from "../api/client";
import { ResponseSuccess } from "../models/api.models";
import { LoginBody, LoginResponse } from "../models/auth.model";


class AuthService {

    async login(body: LoginBody): Promise<ResponseSuccess<LoginResponse> | any> {
        return (await client.post<ResponseSuccess<LoginResponse>>(`/auth/login`, body)).data;
    }

    async logout(): Promise<ResponseSuccess<LoginResponse> | any> {
        return (await client.get<ResponseSuccess<LoginResponse>>(`/auth/logout`)).data;
    }

    async isAuthenticated(): Promise<ResponseSuccess<LoginResponse> | any> {
        return (await client.get<ResponseSuccess<LoginResponse>>(`/auth/isAuth`)).data;
    }

    async refreshToken(): Promise<ResponseSuccess<LoginResponse> | any> {
        return (await client.post<ResponseSuccess<LoginResponse>>(`/auth/refresh`)).data;
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default new AuthService();
