import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { getAccessToken } from "../utils/get-access-token";

const client = axios.create({
  baseURL: 'https://api-chat.duckdns.org',
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true, // To send cookies in the request
  timeout: 2000
});

client.interceptors.request.use(
  (config) => {
    // Get the token
    const token = getAccessToken();

    // If token exists, add it to the request headers
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


client.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response.status === 401) {
    // res.then((response: AxiosResponse<ResponseSuccess<RefreshToken>>) => {
    //   localStorage.setItem('accessToken', response.data.success.data.accessToken);
    //   localStorage.setItem('refreshToken', response.data.success.data.refreshToken);
    // }).catch((error) => {

    // })
    localStorage.clear();
    window.location.replace("/login/");
  }
  return Promise.reject(error);
});
export default client;

