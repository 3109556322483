import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { ResponseSuccess } from "../../models/api.models";
import { Person } from "../../models/person.models";
import personService from "../../services/person-service";

const useGetAllPerson = (page: number = 1, pageSize: number = 15) => {
    return useQuery<ResponseSuccess<Person>>(
        {
            queryFn: () => personService.getAllPerson(page, pageSize),
            queryKey: ['persons', page],
            placeholderData: keepPreviousData
        });
}


export {
    useGetAllPerson
};
