import { useMutation } from "@tanstack/react-query";
import { LoginBody, LoginResponse } from "../../models/auth.model";
import { toast } from 'react-toastify';
import authService from "../../services/auth-service";
import { NavigateFunction } from "react-router";
import { ResponseSuccess } from "../../models/api.models";

const useLogin = (navigate: NavigateFunction) => {

    return useMutation({
        mutationKey: ['login'],
        mutationFn: (body: LoginBody) => authService.login(body),
        onSuccess: async (data: ResponseSuccess<LoginResponse>) => {
            await toast.success('Login was successfully!');
            console.log(data.data);
            localStorage.setItem('accessToken', data.data.accessToken);
            navigate('/');
        }
    });
}


export {
    useLogin
}
