import React, { FC } from 'react';

interface Props {

}

const Home: FC<Props> = () => {
return <div>
    <p>
      Home Page
    </p>
</div>
};

export default Home;
