
import client from "../api/client";
import { ResponseSuccess } from "../models/api.models";
import { LoginBody, LoginResponse } from "../models/auth.model";
import { Person } from "../models/person.models";


class PersonService {

    async getAllPerson(page: number, pageSize: number): Promise<ResponseSuccess<Person> | any> {
        return (await client.get<ResponseSuccess<Person>>(`/person?page=${page}&pageSize${pageSize}`)).data;
    }

    async savePersons(file: File): Promise<ResponseSuccess<any> | any> {
        const formData = new FormData();
        formData.append('file', file);

        return (await client.post<ResponseSuccess<LoginResponse>>(`/person/save/all`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })).data;
    }

    async isAuthenticated(): Promise<ResponseSuccess<LoginResponse> | any> {
        return (await client.get<ResponseSuccess<LoginResponse>>(`/auth/isAuth`)).data;
    }

    async refreshToken(): Promise<ResponseSuccess<LoginResponse> | any> {
        return (await client.post<ResponseSuccess<LoginResponse>>(`/auth/refresh`)).data;
    }
}


// eslint-disable-next-line import/no-anonymous-default-export
export default new PersonService();
