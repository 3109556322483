import React, { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { JwtPayload } from '../models/auth.model';
import { decodeJwt } from '../utils/decodeJwt';
import PrivateRoute from '../components/PrivateRoute';

interface Props {
    isAuth: boolean;
    setIsAuth: (value: boolean) => void;
}

const RequireAuth: FC<Props> = ({ isAuth, setIsAuth }) => {
    const navigate = useNavigate();
    let location = useLocation();
    let path = location.pathname
    const checkTokenExpiration = async () => {
        const token: JwtPayload | string = decodeJwt();
        if (typeof token !== 'string') {
            setIsAuth(false);
            console.log("token.exp", token.exp);
            const expirationTime = new Date(token.exp).getTime();
            console.log("expirationTime", expirationTime)
            const currentTime = new Date().getTime() / 1000;
            // const currentTime = 1711044184;
            if (currentTime < expirationTime) {
                setIsAuth(false);
                console.log(currentTime)
                // const res = getRefreshTokenSRV();
                // res.then((response: AxiosResponse<ResponseSuccess<RefreshToken>>) => {
                //     localStorage.setItem('accessToken', response.data.success.data.accessToken);
                //     localStorage.setItem('refreshToken', response.data.success.data.refreshToken);
                // }).catch((error) => {
                //     localStorage.clear();
                //     setIsTokenExpired(true);
                // });
            }
            if(path.includes('login')){
                
                navigate('/');
            }
            console.log('login');
            navigate('/');
        } else {
            localStorage.clear();
            setIsAuth(true);
        }
    };

    useEffect(() => {
        checkTokenExpiration();
    }, []);

    useEffect(() => {
        console.log(isAuth);
    });



    return <PrivateRoute isAuth={isAuth}/>
};

export default RequireAuth;
