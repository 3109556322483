import { useMutation } from "@tanstack/react-query";
import { toast } from 'react-toastify';
import personService from "../../services/person-service";
import { ResponseSuccess } from "../../models/api.models";

const useSaveAllPerson = (refetch: any) => {

    return useMutation({
        mutationKey: ['save all person'],
        mutationFn: (file: File) => personService.savePersons(file),
        onSuccess: async (data: ResponseSuccess<any>) => {
            await toast.success('Persons was add successfully!');
            refetch();
        }
    });
}


export {
    useSaveAllPerson
}
