import * as React from 'react';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import AvatarWithStatus from './AvatarWithStatus';
import ChatBubble from './ChatBubble';
import MessageInput from './MessageInput';
import { ChatProps, MessageProps } from '../../models/template.type';
import MessagesPaneHeader from './MessagesPaneHeader';
import { useGetChatMessages } from '../../hooks';
import { MessageContent } from '../../models/chat.models';

type MessagesPaneProps = {
  chatId: number;
};

export default function MessagesPane(props: MessagesPaneProps) {
  const { chatId } = props;
  const {data, isSuccess} = useGetChatMessages(chatId, 1, 15);
  // const [chatMessages, setChatMessages] = React.useState(chat.messages);
  const [textAreaValue, setTextAreaValue] = React.useState('');

  // React.useEffect(() => {
  //   setChatMessages(chat.messages);
  // }, [chat.messages]);

  return (
    <Sheet
      sx={{
        height: { xs: 'calc(100dvh - var(--Header-height))', lg: '100dvh' },
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.level1',
      }}
    >
      {/* <MessagesPaneHeader sender={chat.sender} /> */}
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          minHeight: 0,
          px: 2,
          py: 3,
          overflowY: 'scroll',
          flexDirection: 'column-reverse',
        }}
      >
        <Stack spacing={2} justifyContent="flex-end">
          {
            isSuccess
            ?
            data.data.content.map((message: MessageContent, index: number) => {
              const isYou = message.sender === 'you';
              return (
                <Stack
                  key={index}
                  direction="row"
                  spacing={2}
                  flexDirection={isYou ? 'row-reverse' : 'row'}
                >
                  {/* {message.sender !== 'You' && (
                    <AvatarWithStatus
                      online={message.sender.online}
                      src={message.sender.avatar}
                    />
                  )} */}
                  <ChatBubble variant={isYou ? 'sent' : 'received'} {...message} />
                </Stack>
              );
            })
            :
            <>
            </>
          }
        </Stack>
      </Box>
      <MessageInput
        textAreaValue={textAreaValue}
        setTextAreaValue={setTextAreaValue}
        onSubmit={() => {
          // const newId = chatMessages.length + 1;
          // const newIdString = newId.toString();
          // setChatMessages([
          //   ...chatMessages,
          //   {
          //     id: newIdString,
          //     sender: 'You',
          //     content: textAreaValue,
          //     timestamp: 'Just now',
          //   },
          // ]);
        }}
      />
    </Sheet>
  );
}