import React, { useEffect, useState } from 'react';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import CssBaseline from '@mui/joy/CssBaseline';
import RequireAuth from './hoc/RequireAuth';
import MainLayout from './layouts/MainLayout';
import Message from './pages/Message';
import Home from './pages/Home';
import MyProfile from './pages/MyProfile';
// import { socket } from './api/socket';
import { ToastContainer, toast } from 'react-toastify';
import { Login } from './pages/Login';
import Person from './pages/Person';

function App() {
  const [isTokenExpired, setIsTokenExpired] = useState<boolean>(true);
  // const [isConnected, setIsConnected] = useState(socket.connected);
  // const [fooEvents, setFooEvents] = useState([]);

  // useEffect(() => {
  //   function onConnect() {
  //     setIsConnected(true);
  //   }

  //   function onDisconnect() {
  //     setIsConnected(false);
  //   }

  //   // function onFooEvent(value: any) {
  //   //   setFooEvents(previous => [...previous, value]);
  //   // }

  //   socket.on('connect', onConnect);
  //   socket.on('disconnect', onDisconnect);
  //   // socket.on('foo', onFooEvent);

  //   return () => {
  //     socket.off('connect', onConnect);
  //     socket.off('disconnect', onDisconnect);
  //     // socket.off('foo', onFooEvent);
  //   };
  // }, []);

  return (
    <CssVarsProvider defaultMode="dark" disableTransitionOnChange>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <BrowserRouter>
        <Routes>
          <Route path='' element={<RequireAuth isAuth={isTokenExpired} setIsAuth={setIsTokenExpired}/>} >
            <Route index element={<MainLayout
              children={
                <Home />
              }
            />} />
            <Route path='/messages' element={<MainLayout
              children={
                <Message />
              }
            />} />
            <Route path='/profile' element={<MainLayout
              children={
                <MyProfile />
              }
            />} />
            <Route path='/person' element={<MainLayout
              children={
                <Person />
              }
            />} />
          </Route>
          <Route path='/login' element={<Login isAuth={isTokenExpired}/>} />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </CssVarsProvider>
  );
}

export default App;
